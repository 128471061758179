<template>
  <modal
    name="modal-preview-table"
    scrollable
    width="100%"
    height="100%"
    :clickToClose="false"
  >
    <!-- Cabeçalho do Modal -->
    <div class="modal-preview-cabecalho m-3">
      <div
        class="back-arrow-squared"
        @click="fecharModalPreviewTable"
        title="Fechar visualização"
      >
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
    </div>

    <!-- Conteúdo da Tabela -->
    <div class="tabela-container">
      <div class="paginacao-container">
        <div class="pagination-control">
          <b-pagination
            v-model="paginaAtual"
            style="margin: 0"
            :total-rows="rows"
            :per-page="porPaigna"
            aria-controls="previewTable"
            size="md"
          ></b-pagination>
          <b-form-select
            v-model="porPaigna"
            :options="paginaQuantidadeOpcoes"
            class="paginas-select"
            size="md"
          ></b-form-select>
        </div>

        <!-- Botão de Exportar -->
        <b-button
          variant="outline-success"
          class="btn-exportar ml-auto"
          @click="exportarExcel"
          v-if="isAccountAdmin || checkSuperAdmin"
          title="Exportar para Excel"
        >
          <i class="flaticon-download"></i>
          Exportar
        </b-button>
      </div>

      <!-- Tabela de Visualização -->
      <b-table
        id="previewTable"
        class="table-bordered"
        :items="tableContent"
        :per-page="porPaigna"
        :current-page="paginaAtual"
        small
        striped
        hover
        responsive
        :fields="tableHeaders"
        head-variant="light"
        thead-class="table-header-center-align"
      ></b-table>
    </div>
  </modal>
</template>

<script>
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";

export default {
  props: ["tableContent", "tableHeaders"],
  created() {
    // Ajustando o cabeçalho da tabela.
    this.tableHeaders = this.tableHeaders.map((header) => ({
      key: header,
      label: header,
      sortable: true
    }));
  },
  data() {
    return {
      porPaigna: 10, // Aumente o padrão para melhor visualização
      paginaAtual: 1,
      paginaQuantidadeOpcoes: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      sortBy: "", // Coluna padrão para ordenação (deixe vazio para iniciar sem ordenação)
      sortDesc: false // Ordem crescente por padrão
    };
  },
  methods: {
    onSortChange(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
    fecharModalPreviewTable() {
      this.$modal.hide("modal-preview-table");
    },
    formatarDataMenorQue10(data) {
      return data < 10 ? "0" + data : data;
    },
    exportarExcel() {
      const dataHoje = new Date();
      const dataFormatada = `${this.formatarDataMenorQue10(
        dataHoje.getDate()
      )}-${this.formatarDataMenorQue10(
        dataHoje.getMonth() + 1
      )}-${dataHoje.getFullYear()} ${this.formatarDataMenorQue10(
        dataHoje.getHours()
      )}:${this.formatarDataMenorQue10(
        dataHoje.getMinutes()
      )}:${this.formatarDataMenorQue10(dataHoje.getSeconds())}`;
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.tableContent);
      XLSX.utils.book_append_sheet(wb, ws, "Preview");
      XLSX.writeFile(wb, `Preview ${dataFormatada}.xlsx`);
    }
  },
  computed: {
    rows() {
      return this.tableContent.length;
    },
    ...mapGetters(["checkSuperAdmin", "isAccountAdmin"])
  }
};
</script>

<style>
/* Modal em tela cheia */
.vm--container.scrollable .vm--modal {
  overflow: hidden;
}

/* Tabela ocupando toda a tela com rolagem vertical */
.tabela-container {
  height: calc(100vh - 50px); /* Altura total menos a altura do cabeçalho */
  padding: 0 16px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf #f0f0f0;
}

/* Estilos da Barra de Rolagem */
.tabela-container::-webkit-scrollbar {
  width: 8px;
}

.tabela-container::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.tabela-container::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 4px;
}

/* Estilos da Paginação */
.paginacao-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

/* Controle de exibição do seletor de páginas */
.paginacao-container .pagination-control {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.paginacao-container .paginas-select {
  width: 100px !important;
}

/* Botão de Fechar no Modal */
.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-arrow-squared:hover {
  background-color: #f0f0f0;
}

/* Cabeçalho da Tabela Alinhado à Esquerda e Destacado */
.table-header-center-align th {
  text-align: center !important;
  position: sticky;
  top: 0;
  background-color: #1565c0; /* Cor de fundo para destaque */
  color: #ffffff; /* Cor do texto branca para contraste */
  font-weight: bold;
  z-index: 10;
  border-bottom: 3px solid #0d47a1; /* Borda inferior para divisão */
  padding: 12px;
}

#previewTable tbody tr td {
  text-align: center;
}

/* Estilização Alternada de Linhas para Melhor Leitura */
.b-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.b-table tbody tr:hover {
  background-color: #e3f2fd; /* Azul claro para realce */
  cursor: pointer;
}
</style>
